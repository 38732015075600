import { ChakraProvider } from "@chakra-ui/react";
import { ChainID, ChainName, OmnityWidgetProps, TabAction } from "./types";
import { HubProvider } from "@context/OmnityHubContext";
import posthog from "posthog-js";
import { extendDefaultTheme } from "./themes/bitfinity/theme";
import Bitfinity from "./Bitfinity";
import WalletKitProvider from "./wallet-kits";
import { DEFAULT_TOKEN } from "@utils/constants";

posthog.init("phc_UtXfkZaaPLBCmniOY2tuSDGj8bCkTiIuGp0ZnAuFBUO", {
  api_host: "https://us.i.posthog.com",
  person_profiles: "always",
  enable_recording_console_log: false,
  disable_session_recording: true,
});

export default function BitfinityWidget({
  assetType,
  customs = ChainID.sICP,
  sourceChain = ChainName.ICP,
  targetChain = ChainName.Bitfinity,
  tokenId,
  network = "mainnet",
  colorMode = "system",
  width = 540,
  reversible = false,
  isPeerToPeer = false,
  tab = TabAction.Transfer,
  onTabChange,
  onParamsChange,
}: OmnityWidgetProps) {
  const _tab = tab ?? TabAction.Transfer;

  const config = {
    initialColorMode: colorMode,
    useSystemColorMode: colorMode === "system" ? true : false,
  };

  const theme = extendDefaultTheme({ config });
  const _tokenId = !!tokenId ? tokenId : DEFAULT_TOKEN[customs];
  return (
    <ChakraProvider theme={theme}>
      <WalletKitProvider>
        <HubProvider
          assetType={assetType}
          customs={customs}
          network={network}
          tab={_tab}
          theme="bitfinity"
          onTabChange={onTabChange}
        >
          <Bitfinity
            customs={customs}
            width={width}
            sourceChain={sourceChain}
            targetChain={targetChain}
            tokenId={_tokenId}
            reversible={reversible}
            isPeerToPeer={isPeerToPeer}
            tab={_tab}
            onTabChange={onTabChange}
            onParamsChange={onParamsChange}
          />
        </HubProvider>
      </WalletKitProvider>
    </ChakraProvider>
  );
}
